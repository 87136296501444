import { FormApiService } from "@/core/services/formApi.service";

const RESOURCE = 'students'

export const getStudentFormStatusByStudentUuid = (studentUuid) => {
  return FormApiService.get(`${RESOURCE}/${studentUuid}/form-status`);
};

export default {
  getStudentFormStatusByStudentUuid,
};
